<template>
  <div class="sub_component">
    <h2>{{ question.label }}</h2>
    <v-expansion-panels v-model="panelsOpened">
      <v-expansion-panel
          v-for="(answer, indexAnswer) in localeAnswers"
          :key="`${answer}-${indexAnswer}`"
      >
        <v-expansion-panel-header>{{ panelTitle }} - {{ indexAnswer + 1 }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <header>
            <v-btn fab
                   depressed
                   small
                   @click="onDisplayConfirmDialog({
                       questionCode: question.code,
                       index: indexAnswer,
                       instanceName: `${panelTitle} ${indexAnswer + 1}`
                       })"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </header>
          <question-reader v-for="(subquestion, index) in question.subquestions"
                           :key="`${subquestion}-${index}-${indexAnswer}`"
                           :value="localeAnswers[indexAnswer][subquestion.code]"
                           :answers="localeAnswers[indexAnswer]"
                           :display="true"
                           :is-read-survey="isReadSurvey"
                           :question="subquestion"
                           @update-value="onUpdateSubValue($event, indexAnswer)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn fab depressed small @click="$emit('add-sub-question', question.code)">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <confirm-dialog v-model="dialog.display"
                    :title="$t('survey.sub.delete.title')"
                    :body="$t('survey.sub.delete.content', {instanceName: dialog.instanceName})"
                    persistent
                    @valid="onRemovePanel"
                    @cancel="onCloseDialog"
    />
  </div>
</template>

<script>
import QuestionReader from '@/components/Survey/QuestionReader'
import questionMixins from '@/components/Survey/Reader/questionMixins'
import ConfirmDialog from '@/components/Template/ConfirmDialog'

export default {
  name: 'Sub',

  mixins: [questionMixins],

  components: {
    ConfirmDialog,
    'question-reader': QuestionReader,
  },

  props: {
    answers: {
      required: true,
      type: Object,
    },
    question: {
      required: true,
      type: Object,
    },
  },

  data: () => ({
    panelsOpened: undefined,
    dialog: {
      display: false,
      questionCode: null,
      index: null,
      instanceName: '',
    },
  }),

  computed: {
    localeAnswers () {
      return this.answers[this.question.code]
    },
    panelTitle () {
      return this.question.label.split(' (')[0]
    },
  },

  methods: {
    onCloseDialog () {
      this.dialog.display = false
      this.dialog.questionCode = null
      this.dialog.index = null
      this.dialog.instanceName = ''
    },
    /**
     * @param {Object} payload
     * @param {String} payload.questionCode
     * @param {Number} payload.index
     * @param {String} payload.instanceName
     */
    onDisplayConfirmDialog ({ questionCode, index, instanceName }) {
      this.dialog.questionCode = questionCode
      this.dialog.instanceName = instanceName
      this.dialog.index = index
      this.dialog.display = true
      this.panelsOpened = undefined
    },
    onRemovePanel () {
      this.$emit('remove-sub-question', {
        questionCode: this.dialog.questionCode,
        index: this.dialog.index,
      })
      this.onCloseDialog()
    },
    onUpdateSubValue (newValue, indexAnswer) {
      this.$emit('update-value', { ...newValue, indexAnswer, subQuestionOf: this.question.code })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

.sub_component {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-align: left;
    width: 100%;
  }

  .v-expansion-panel {
    background-color: $whiteTransparent !important;

    > button {
      font-size: 20px;
    }

    header {
      display: flex;
      justify-content: flex-end;
    }
  }

  .v-btn {
    margin: 15px 0;
  }
}
</style>
